import React from "react"
import styled from "styled-components"
import contentData from "./data.json"
import Button from "../../Button"

const HeroTextST = ({
  lang,
  download,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  heroTextMargin,
}) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]
  const HeroTextST = styled.section`
    overflow: visible;
  `
  return (
    <HeroTextST data-theme="light" id="top" className="HeroTextST">
      <div className="container container--sm margin-top--lg">
        <div className={`col col--12 margin-bottom--md ${heroTextMargin}`}>
          {content &&
            content.map(main => (
              <div hey={main} className="text--component text--center">
                <h1>{main.title}</h1>
                <p className="">{main.text}</p>
                {download && (
                  <Button
                    dataGoal={downloaddataGoal}
                    href={downloadhref}
                    classname={downloadclassname}
                    text={downloadtext}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </HeroTextST>
  )
}

export default HeroTextST
