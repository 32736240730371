import React, { useState, useEffect } from "react"
import styled from "styled-components"
import contentData from "../../../components/staticSections/CtaMailCardST/data.json"

const CtaMailCardST = ({ lang, link, dataTheme }) => {
  const CtaMailCardST = styled.section`
    background-color: #fff;
    input[type="email"] {
      border-radius: 50px;
      text-align: center;
    }
    input[type="submit"] {
      width: 100%;
    }
    .accessFormCard {
      padding: 24px;
      height: auto;
    }
    @media only screen and (min-width: 768px) {
      .accessFormCard {
        padding: 74px;
      }
    }
  `
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]

  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.MERGE0(event)
    }
  }
  const [number, setNumber] = useState("")
  let d = new Date()
  let add1 = d.getDate() * 52
  let add2 = d.getDate() * 2
  let add3 = add1 + add2

  const thousands_separators = num => {
    var num_parts = num.toString().split(".")
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return num_parts.join(".")
  }
  useEffect(() => {
    if (typeof number != "undefined" && number != null) {
      var add4 = 1 + 1000 + add3 + 1
      setNumber(thousands_separators(add4))
    }
  }, [number, add3])
  return (
    <CtaMailCardST data-theme={dataTheme} className="CtaMailCardST">
      <div className="container container--sm margin-bottom--lg">
        <div className="card col col--12 col--md-12 flex flex--center accessFormCard">
          {content &&
            content.map(main => (
              <div
                // style={{ maxWidth: "520px" }}
                key={main}
                className="text--component text--center"
              >
                <p className="margin-bottom--md">
                  <span
                    id="number"
                    className="text--lg text--bold number color--electric-blue"
                  >
                    {number}
                  </span>{" "}
                  {main.text}
                </p>

                <form
                  id="trialForm"
                  action="https://jointacademy.us14.list-manage.com/subscribe/post"
                  method="post"
                  className="validate form"
                  data-goal="Onboarding Viewed"
                  onSubmit={onSubmit}
                >
                  <input
                    type="hidden"
                    name="u"
                    value="af6b2530d423199a95fe20a13"
                  />
                  <input type="hidden" name="id" value="f0211e95bb" />
                  <div>
                    <input
                      type="email"
                      autoCapitalize="off"
                      autoCorrect="off"
                      name="MERGE0"
                      id="MERGE0"
                      className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
                      placeholder={main.placeholder}
                      onFocus={e => (e.target.placeholder = "")}
                      onBlur={e =>
                        (e.target.placeholder = `${main.placeholder}`)
                      }
                      required
                    />
                    <input
                      id="emailCampaign"
                      type="submit"
                      value={main.buttonText}
                      name="submit"
                      className="btn btn--primary btn--md btn--fullwidth-sm"
                    />
                  </div>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_af6b2530d423199a95fe20a13_7c0cc7163d"
                      tabIndex="-1"
                    />
                  </div>
                </form>

                <p className="margin-top--sm text--xs">
                  {main.subText}{" "}
                  <a target="_blank" rel="noreferrer" href={main.link}>
                    {main.linkText}
                  </a>
                </p>
              </div>
            ))}
        </div>
      </div>
    </CtaMailCardST>
  )
}

export default CtaMailCardST
