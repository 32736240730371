import React from 'react';
import Layout from '../../components/layout';
import HeroTextST from '../../components/staticSections/HeroTextST/HeroTextST';
import Seo from '../../components/seo';
import CtaMailCardST from '../../components/staticSections/CtaMailCardST/CtaMailCardST';
import SocialProofST from '../../components/staticSections/SocialProofST/SocialProofST';
import Segment from '../../components/segment';

const IndexPage = () => {
  const lang = 'fr_fr';
  return (
    <Layout lang="fr" headerWitdh="header" dataTheme="white" showFooter>
      <Segment id="15aFPY6NamUQUAN3oIEqSx0D2mNElDF7" />
      <Seo title="Blue Shield of California" language="fr" />
      <HeroTextST lang={lang} heroTextMargin="margin-top--sm" />
      <CtaMailCardST dataTheme="light" lang={lang} />
      <SocialProofST lang={lang} />
    </Layout>
  );
};

export default IndexPage;
