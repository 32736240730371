import React from "react"
import styled from "styled-components"
import dataSocial from "./data.json"
import Image from "../../image"

const SocialProofST = ({ lang, storybook, storybookIconUrl }) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang

  const content = dataSocial[language]
  const SocialProofST = styled.section`
    .socialData {
      justify-content: space-evenly;
      div {
        flex-shrink: 0;
      }
    }
  `
  return (
    <SocialProofST data-theme="light" className="SocialProofST">
      <div className="container container--lg  margin-bottom--xl margin-bottom--sm">
        <div className="stats_bar">
          {content &&
            content.map(main => (
              <div key={main} className="float grid flex flex--center-y">
                <div className="col col--12 col--lg-12">
                  <div className="flex flex--center-y socialData">
                    {main.data &&
                      main.data.map(data => (
                        <div
                          key={data.title}
                          className="text--center"
                          style={{ flexShrink: "0" }}
                        >
                          <p
                            className="text--xl text--title text--700"
                            style={{ lineHeight: "1em" }}
                          >
                            {data.data}
                          </p>

                          <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                            {data.title}
                          </p>
                          {storybook ? (
                            <img src={data.storybookIcon} alt="sb pic" />
                          ) : (
                            <Image
                              classname="social-icons"
                              filename={data.icon}
                              alt={data.icon}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </SocialProofST>
  )
}

export default SocialProofST
